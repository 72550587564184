import React from 'react'
import { includes, get } from 'lodash'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Layout from '../components/layout'
import Seo from '../components/seo'
import DisplayFont from '../components/display-font'
import HeroSplit from '../components/hero-split'
import VideoPlayer from '../components/video-player'
import Container from '../components/container'

import * as styles from './character.module.css'

class CharacterTemplate extends React.Component {
  render() {
    const letter = get(this.props, 'data.contentfulCharacter')
    const next = get(this.props, 'data.next')
    const previous = get(this.props, 'data.previous')

    function getInstagramHandle(url) {
      const urlArray = url.split('/');
      const lastItem = urlArray[urlArray.length - 1];
      let username = lastItem;
      if (lastItem === '' || includes(lastItem, '?')) {
        username = urlArray[urlArray.length - 2];
      }
      return `@${username}`;
    }

    return (
      <Layout className='character-template'>
        <Seo
          title={letter.person.name}
          image={`http:${letter.heroImage?.resize?.src}`}
        />
        <HeroSplit images={letter.person.heroImages} />
        <Container>
          {letter?.person?.word && (
            <div className={styles.word}>
              <DisplayFont as="h1">{letter.person.word}</DisplayFont>
            </div>
          )}
        </Container>
        <Container isNarrow>
          {letter.person?.vimeoUrl && (
            <div className={styles.videoContainer}>
              <VideoPlayer url={letter?.person?.vimeoUrl} />
            </div>
          )}
        <div className={styles.layout}>
          <div className="left-col">
            {letter?.person?.portrait && (
              <GatsbyImage alt={`${letter.person.name}'s portrait`} image={letter.person.portrait.gatsbyImage} />
            )}
          </div>
          <div className={styles.rightCol}>
            {letter.person?.shortBio?.raw &&
              renderRichText(letter.person.shortBio)}
            {letter.person?.website &&
              <>
                <a href={letter.person?.website} target="_blank" rel="noreferrer">
                  {letter.person.name}'s website
                </a>
                <br />
              </>}
              {letter.person?.instagram &&
              <>
              <a href={letter.person?.instagram} target="_blank" rel="noreferrer">
                {getInstagramHandle(letter.person?.instagram)}
              </a>
              <br />
              </>}
              {letter.person?.instagramAlt &&
              <>
              <a href={letter.person?.instagramAlt} target="_blank" rel="noreferrer">
                {getInstagramHandle(letter.person?.instagramAlt)}
              </a>
              <br />
              </>}
          </div>
        </div>
        {(previous || next) && (
          <nav>
            <ul className={styles.paginationControls}>
              {previous && (
                <li className={styles.paginationControlItem}>
                  <Link to={`/${previous.slug}`} rel="prev">
                    ← {previous.character}
                  </Link>
                </li>
              )}
              {next && (
                <li className={styles.paginationControlItem}>
                  <Link to={`/${next.slug}`} rel="next">
                    {next.character} →
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
        </Container>
      </Layout>
    )
  }
}

export default CharacterTemplate

export const pageQuery = graphql`
  query CharacterBySlug($slug: String!, $previous: String, $next: String) {
    contentfulCharacter(slug: { eq: $slug }) {
      slug
      character
      person {
        name
        word
        vimeoUrl
        website
        instagram
        instagramAlt
        shortBio {
          raw
        }
        heroImages {
          gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, height: 1000)
        }
        portrait {
          gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
          resize(height: 630, width: 1200) {
            src
          }
        }
      }
    }
    previous: contentfulCharacter(slug: { eq: $previous }) {
      slug
      character
    }
    next: contentfulCharacter(slug: { eq: $next }) {
      slug
      character
    }
  }
`
