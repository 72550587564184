import React from 'react'

import * as styles from './display-font.module.css'

const DisplayFont = ({children, as = 'h2', characterAlt, ...rest}) => {
  const Tag = as
  return (
    <Tag
      className={characterAlt ?
        styles.displayFontAlt :
        styles.displayFont}
        {...rest}>
      {children}
    </Tag>)
}

export default DisplayFont
