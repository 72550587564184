import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { get } from 'lodash'

import * as styles from './hero-split.module.css'

const DisplayFont = ({ images }) => {
  const imageLeft = get(images, '[0]', null)
  const imageRight = get(images, '[1]', null)
  const descriptionLeft = get(imageLeft, 'description', '');
  const descriptionRight = get(imageRight, 'description', '');

  return (
    <div className={styles.container}>
      <div className={styles.heroSplitContainer}>
        <GatsbyImage
          alt={descriptionLeft}
          image={imageLeft.gatsbyImage}
        />
        <GatsbyImage
          alt={descriptionRight}
          image={imageRight.gatsbyImage}
        />
      </div>
      {(descriptionLeft || descriptionRight) && (
        <div className={styles.descriptionContainer}>
          <div>
            <p>{descriptionLeft}</p>
          </div>
          <div>
            <p>{descriptionRight}</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default DisplayFont
